/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import seoParams from '../manifest/seo';

const Seo = () => {
  const { title, description } = seoParams(typeof window !== 'undefined' ? window.location.pathname : {});
  return (
    <Helmet
      htmlAttributes={{
        lang: 'fr'
      }}
      title={title}
      titleTemplate={title ? `%s | ${title}` : null}
      meta={[
        {
          name: 'url',
          content: 'https://www.hypnose-venture.fr/'
        },
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:title',
          content: 'Hypnose venture'
        },
        {
          property: 'og:image',
          content: '../img/freedom.jpg'
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:type',
          content: 'website'
        }
      ]}
    />
  );
};

export default Seo;
