const SEOParams = (params) => {
  const defaultParams = {
    title: "Hypnose Ericksonienne et hypnose Intégrative dans le Val-d'Oise",
    description: "Hypnothérapeute spécialisée dans l'arrêt du tabac, la gestion du poids, la confiance ainsi que la douleur à Parmain dans le Val-d'Oise. Francine Lampin, praticienne en hypnose Ericksonienne et intégrative"
  };
  return {
    '/': defaultParams,
    '/hypnose-seance': {
      title: "Séance d'hypnose thérapeutique à Parmain dans le Val-d'Oise",
      description: "Francine Lampin est une praticienne en hypnose Ericksonienne et en hypnose intégrative. Elle vous reçoit pour des séances d'hypnose à son cabinet de Val-d'Oiseème arrondissement."
    },
    '/hypnose-arret-du-tabac': {
      title: "Arrêt du tabac par hypnose à Parmain (Val-d'Oise)",
      description: "L'hypnose est une méthode efficace pour vous aider vous libérer de la cigarette et à arrêter de fumer sans manque. Hypnothérapeute spécialisée dans l’arrêt du tabac à Parmain dans le Val-d'Oise."
    },
    '/coherence-cardiaque': {
      title: "Arrêter de fumer par hypnose à Parmain (Val-d'Oise)",
      description: "La cohérence cardiaque est une pratique personnelle de la gestion du stress et des émotions . Hypnothérapeute spécialisée dans l’arrêt du tabac à Parmain dans le Val-d'Oise."
    },
    '/access': {
      title: "Hypnose arrêt tabac - Access bars par hypnose dans le Val-d'Oise",
      description: "L'hypnose est une méthode efficace pour vous aider vous libérer de la cigarette et à arrêter de fumer sans manque. Hypnothérapeute spécialisée dans l’arrêt du tabac à Parmain dans le Val-d'Oise."
    },
    '/certification': {
      title: 'Hypnose venture | Hypnose ericksonienne et intégrative® - Certification',
      description: "Hypnothérapeute spécialisée dans l'arrêt du tabac, la gestion du poids, la confiance ainsi que la douleur à Parmain dans le Val-d'Oise. Francine Lampin, praticienne en hypnose Ericksonienne et intégrative."
    },
    '/hypnose-pnl': {
      title: "Vivre de manière épanouie par hypnose à Parmain dans le Val-d'Oise",
      description: "La P.N.L vous aidera à contourner ou remettre en place ces différentes phases vécues à Parmain dans le Val-d'Oise."
    },
    '/contact': {
      title: "Cabinet d'hypnothérapie - Hypnothérapeute à Parmain (Val-d'Oise)",
      description: "Contactez Francine Lampin, praticienne en hypnose Ericksonienne et intégrative, pour toute question. Cabinet d'hypnothérapie, 107 allée des peupliers, 95620 Parmain (Val-d'Oise)."
    },
    '/prestations': {
      title: "Tarifs des séances d'hypnose et de sevrage tabagique à Parmain",
      description: "Hypnothérapeute spécialisée dans l'arrêt du tabac, la gestion du poids, la confiance ainsi que la douleur à Parmain dans le Val-d'Oise. Consultez les tarifs des séances et forfaits."
    },
    '/faq': {
      title: "Questions sur les séances d'hypnose dans le Val-d'Oise",
      description: "Découvrez les réponses aux questions fréquemment posées concernant les séances d'hypnose du cabinet Hypnose Venture (Val-d'Oise) arrondissement."
    },
    '/mentions-legales': {
      title: 'Hypnose venture | Hypnose ericksonienne et intégrative® - Mentions Légales',
      description: "Hypnothérapeute spécialisée dans l'arrêt du tabac, la gestion du poids, la confiance ainsi que la douleur à Parmain dans le Val-d'Oise. Francine Lampin, praticienne en hypnose Ericksonienne et intégrative"
    }
  }[params] || defaultParams;
};

export default SEOParams;
